//================================================================
//  Component: HistoryCard component
//================================================================

//  Purpose: This is a subcomponent of the Chat.js page

//  Properties:
//    - formData = {useReducer, Chat.js useReducer}
//    - setFormData = {useReducer, Chat.js useReducer}
//    - threadid = {string, Firestore threadid}
//    - displayname = {string, Firestore displayname}
//    - answer = {string, Firestore answer}
//    - answerdate = {timestamp, Firestore answerdate}

//  Example:
//    <HistoryCard
//      formData={formData}
//      setFormData={setFormData}
//      threadid={threadid}
//      displayname={displayname}
//      answer={answer}
//      answerdate={answerdate}
//    ></HistoryCard>    

//================================================================


//Libraries
import React, { useEffect, useState } from 'react';

//Contexts

//Components

//Functions
import convertDate from '../../../Library/ConvertDate';

//Images

//CSS


export default function HistoryCard({
    index,
    formData,
    setFormData,
    threadid,
    displayname,
    description,
    created,
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
  
  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    const [style, setStyle] = useState('flex flex-col p-2 border rounded-md mb-2 cursor-pointer hover:shadow-lg');
    
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function ChangeThread(){

      formData.unsubscribe();

      setFormData({
        'threadid': threadid,
        'threadtype': 'existing',
      });

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    useEffect(() => {

      if (formData.threadid === threadid) return setStyle('flex flex-col p-2 border-2 border-b-[#00a7a4] rounded-md mb-2');

      setStyle('flex flex-col p-2 border-2 rounded-md mb-2 cursor-pointer hover:shadow-lg');

    }, [formData.threadid, threadid]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  // Show all other cards
  return (
    <div key={index} className={style} onClick={() => ChangeThread()}>

      <div className='flex flex-row'>

        {/* Check Box */}
        <label className='Radio-Buttons-Container'>
          <input
            type='checkbox'
            checked={formData.selectedThreads.indexOf(threadid) > -1 ? true : false}
            onClick={(e) => {

              // Add or remove the threadid from the useReducer state 'selectedThreads'
              // Allows us to deleted all the documents in a thread

              if (e.target.checked === true) {

                formData.selectedThreads.push(threadid);
                return setFormData(formData);

              }

              const index = formData.selectedThreads.indexOf(threadid)

              if (index === -1) return;

              formData.selectedThreads.splice(index);
              setFormData(formData);
          
          }}
          ></input>
          <span className='Radio-Checkmark'></span>
        </label>

        {/* Displayname */}
        <h6>{displayname}</h6>

      </div>

      {/* description */}
      <div className='flex flex-col font-light p-3'>
        <p className='h-[50px] text-ellipsis line-clamp-2'>
          {description}
        </p>
        <span className='font-normal text-right'>
          {convertDate(created)}
        </span>
      </div>

    </div>
  )

}
